var DynamicLabel = function(domElement) {
    this.domElement = domElement;
    this.inputSelector = '.ginput_container > input';
    this.fieldContainerClass = 'gfield';
    this.focusClass = 'focus';
    this.textAreaSelector = '';

    this.initialFocus = function() {
        var inputElements = this.domElement.querySelectorAll(this.inputSelector);
        var textAreaElements = this.domElement.querySelectorAll(this.textAreaSelector);
        for (var i = 0; i < inputElements.length; i++) {
            if (inputElements[i].value !== '') {
                var currentElement = this.findFieldContainer(inputElements[i]);
                var labelElement = currentElement.querySelector('label');
                labelElement.classList.add(this.focusClass);
            }
        }
        for (var i = 0; i < textAreaElements.length; i++) {
            if (textAreaElements[i].value !== '') {
                var currentElement = this.findFieldContainer(textAreaElements[i]);
                var labelElement = currentElement.querySelector('label');
                labelElement.classList.add(this.focusClass);
            }
        }
    }

    this.findFieldContainer = function(currentElement) {
        while (!currentElement.classList.contains(this.fieldContainerClass)) {
            if(typeof currentElement.parentElement === 'undefined') return null;
            currentElement = currentElement.parentElement;
        }
        return currentElement;
    }

    this.focusLabel = function(event) {
        if (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA' ) {
            var currentElement = this.findFieldContainer(event.target);
            var labelElement = currentElement.querySelector('label');
            labelElement.classList.add(this.focusClass);
        }
    }

    this.blurLabel = function(event) {
        if (event.target.nodeName === 'INPUT' || event.target.nodeName === 'TEXTAREA') {
            if (event.target.value == '') {
                var currentElement = this.findFieldContainer(event.target);
                var labelElement = currentElement.querySelector('label');
                labelElement.classList.remove(this.focusClass);
            }
        }
    }

    this.bindEvents = function() {
        var inputElements = this.domElement.querySelectorAll(this.inputSelector);
        var textAreaElements = this.domElement.querySelectorAll(this.textAreaSelector);
        for (var i = 0; i < inputElements.length; i++) {
            inputElements[i].addEventListener('focus', this.focusLabel.bind(this));
            inputElements[i].addEventListener('blur', this.blurLabel.bind(this));
        }
        for (var i = 0; i < textAreaElements.length; i++) {
            textAreaElements[i].addEventListener('focus', this.focusLabel.bind(this));
            textAreaElements[i].addEventListener('blur', this.blurLabel.bind(this));
        }
    }

    this.init = function() {
        this.bindEvents();
        this.initialFocus();
    }
}

function initDynamicLabel() {
    window.onload = function() {
        if (document.querySelector('.contact-form')) {
            var dynamicLabel = new DynamicLabel(document.querySelector('.contact-form'));
            dynamicLabel.inputSelector = '.dynamic-input > input';
            dynamicLabel.fieldContainerClass = 'dynamic-input';
            dynamicLabel.textAreaSelector = '.dynamic-input > textarea';
            dynamicLabel.init();
        }
    }
}

initDynamicLabel();