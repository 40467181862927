window.addEventListener('load', function () {
    var hamburger = document.querySelector('.hamburger');
    var navbar = document.querySelector('header');
    var anchorlinks = document.querySelectorAll('a[href^="#"]:not(.js-mobile-anchor)');
    webpImages();
    hamburgerMenu(hamburger);
    navbarFixedOnScroll(navbar);
    scrollToAnchor(anchorlinks);
    initFixedBlock();
})

function webpImages() {
    var images = document.querySelectorAll('.js-image');
    Modernizr.on('webp', function(result) {
        for(var i = 0; i < images.length; i++) {
            if (result) {
                images[i].classList.add('webp');
            } else {
                images[i].classList.add('no-webp');
            }
        }
    });
}

function hamburgerMenu(el) {
    var menuMobile = document.querySelector('#overlay');
    var body = document.querySelector('body');
    var menuLinks = document.querySelectorAll('.js-mobile-anchor');

    el.addEventListener('click', function(){
        if(this.classList.contains('active')) {
            this.classList.remove('active');
            menuMobile.classList.remove('open');
            body.classList.remove('mobile-menu-open');
        } else {
            this.classList.add('active');
            menuMobile.classList.add('open');
            body.classList.add('mobile-menu-open');
        }
    });
    for (var item = 0; item < menuLinks.length; item++) {
        menuLinks[item].addEventListener('click', function() {
            body.style.position = '';
            el.classList.remove('active');
            menuMobile.classList.remove('open');    
        });
    }
}

function initFixedBlock() {
    var el = document.querySelector('.block-animation');
    var rightColumn = document.querySelector('.benefit-column');
    var reachedElement = document.querySelector('#our-story');
    var eltop = el.offsetTop - 115;
    var rtop = (reachedElement.offsetTop - el.clientHeight) - 20;
    if(window.innerWidth >= 1024 ) {
        window.addEventListener('scroll', function () {
            var scroll = this.pageYOffset;
            if(scroll > eltop) {
                el.classList.add('fixed-block');
                rightColumn.classList.add('large-offset-7');
            } else {
                el.classList.remove('fixed-block');
                rightColumn.classList.remove('large-offset-7');
                eltop = el.offsetTop - 115;
            }
            if((scroll + 115) >= rtop) {
               el.style.position = 'absolute';
               el.style.top = rtop + 'px';
            } else {
                el.style.position = '';
                el.style.top = ''; 
            }
        });
    }
}

function scrollToAnchor(anchorlinks) {
    for (var item = 0; item < anchorlinks.length; item++) {
        anchorlinks[item].addEventListener('click', function(e) {
            var hashval = this.getAttribute('href');
            var target = document.querySelector(hashval);
            target.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            })
            history.pushState(null, null, hashval);
            e.preventDefault();
        })
    }
}

function navbarFixedOnScroll(el) {
    var lastScrollTop = 0;
    if (window.innerWidth >= 1024) {
        window.addEventListener('scroll', function() {
            var scroll = this.scrollY;
            if(scroll < lastScrollTop) {
                el.classList.add('fixed-navbar');
                el.classList.remove('hidden-navbar');
            } else {
                if (scroll > 5) { //because of the bouncing back when scrolling to the top
                    el.classList.add('hidden-navbar');
                }
            }
            if(scroll <= 0) {
                el.classList.remove('fixed-navbar');
            }
            lastScrollTop = scroll;
        });
    }
}

function sendEmail(token) {
    this.formContainer = document.querySelector('.form-container');
    this.contactName = document.querySelector("input[name='contact-name']");
    this.contactEmail = document.querySelector("input[name='contact-email']");
    this.contactMessage = document.querySelector("textarea[name='contact-message']");
    this.errorMessages = document.querySelectorAll('[id^="error-"]');
    this.validationMessage = document.querySelector('.validation-message');
    this.buttonForm = document.querySelector("form > button");  
    this.captchaToken = token;
    this.secretKey = '6LeWB48UAAAAAO1JihCW_oTgKytdTBOYWFI-YLph';
    this.formData = {};

    this.onFormErrors = function(response) {
        if(response !== '') {
            response = JSON.parse(response);
            if(response.success) {
                this.ajaxVerifyCaptchaToken();
            } else {   
                for(var error in response.errors) {
                    var element = document.querySelector("#"+error);  
                    element.innerHTML = response.errors[error];
                }
            }
        }
    }

    this.onCaptchaRequest = function(response) {
        var response = JSON.parse(response);
        if(response.success) {
           this.formContainer.classList.add('hidden')
           if(this.validationMessage.classList.contains('error')) {
                this.validationMessage.classList.remove('error');
           }
           this.validationMessage.classList.add('success');
        } else {
            this.validationMessage.classList.add('error');
        }
        this.validationMessage.innerHTML = response.message;
        this.validationMessage.scrollIntoView({
            behavior: 'smooth'
        });
    }

    this.ajaxVerifyCaptchaToken = function() {
        this.formData.action = 'send_email';
        this.formData.secretKey = this.secretKey;
        this.formData.captchaResponse = this.captchaToken;
        var ajax = new AjaxRequest(this.onCaptchaRequest.bind(this), function(error) {console.log(error);});;
        ajax.post('/email/send_mail.php', null, this.formData);
    }

    this.ajaxFormErrors = function() {
        this.formData.action = 'check_errors';
        var ajax = new AjaxRequest(this.onFormErrors.bind(this), function(error) {console.log(error);});
        ajax.post('/email/send_mail.php', null, this.formData);
    }

    this.buttonForm.addEventListener('click', function(e) {
        this.formData.contact_name = contactName.value;
        this.formData.contact_email = contactEmail.value;
        this.formData.contact_message = contactMessage.value;
        for (var i = 0; i < this.errorMessages.length; i++) {
            this.errorMessages[i].innerHTML = '';
        }
        this.validationMessage.innerHTML = '';
        this.ajaxFormErrors();
        e.preventDefault();
    }.bind(this));
}