var AjaxRequest = function(successCallback, errorCallback, progressCallback, endedCallback) {
    this.request = null;
    this.successCallback = successCallback;
    this.errorCallback = errorCallback;
    this.progressCallback = progressCallback;
    this.endedCallback = endedCallback;

    this.onProgress = function(event) {
        if (this.progressCallback) {
            var completion = (event.loaded / event.total) * 100;
            this.progressCallback(completion, event);
        }
    }

    this.onError = function(event) {
        console.error('AjaxRequest error: ' + event.target.status);
        if (this.errorCallback) {
            this.errorCallback(event);
        }
    }

    this.onReadyStateChange = function() {
        if (this.request.status == 200 && this.request.readyState == XMLHttpRequest.DONE) {
            if (this.successCallback) {
                this.successCallback(this.request.responseText);
            }
        }
    }

    this.onLoadEnd = function(event) {
        if (this.endedCallback) {
            this.endedCallback(event);
        }
    }

    this.toFormData = function(obj, form, namespace) {
        var fd = form || new FormData();
        var formKey;
        
        for(var property in obj) {
            if(obj.hasOwnProperty(property) && obj[property]) {
                if (namespace) {
                    formKey = namespace + '[' + property + ']';
                } else {
                    formKey = property;
                }
            
                if (obj[property] instanceof Date) {
                    fd.append(formKey, obj[property].toISOString());
                }
                else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
                    this.toFormData(obj[property], fd, formKey);
                } else {
                    fd.append(formKey, obj[property]);
                }
            }
        }
        return fd;
    }

    this.createQueryParams = function(object) {
        var queryString = '?';
        for (var key in object) {
            queryString += key + '=' + object[key] + '&';
        }
        return queryString.substr(0, queryString.length - 1);
    }

    this.get = function(url, queryParams) {
        if (queryParams && queryParams.constructor === Object) {
            queryParams = this.createQueryParams(queryParams);
        }

        var requestUrl = (queryParams) ? url + queryParams : url;
        this.request.open('GET', requestUrl, true);
        this.request.send(null);
    }

    this.post = function(url, queryParams, body) {
        body = body || null;
        if (queryParams && queryParams.constructor === Object) {
            queryParams = this.createQueryParams(queryParams);
        }
        if (body && body.constructor === Object) {
            body = this.toFormData(body);
        }

        var requestUrl = (queryParams) ? url + queryParams : url;
        this.request.open('POST', requestUrl, true);
        this.request.send(body);
    }

    this.init = function() {
        this.request = new XMLHttpRequest();
        this.request.onProgress = this.onProgress.bind(this);
        this.request.onError = this.onError.bind(this);
        this.request.onreadystatechange = this.onReadyStateChange.bind(this);
        this.request.onLoadEnd = this.onLoadEnd.bind(this);
    }

    this.init();
}